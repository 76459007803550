module.exports = [{
      plugin: require('../../node_modules/@kogk/gatsby-plugin-i18n/gatsby-browser.js'),
      options: {"plugins":[],"availableLanguages":["en"],"translations":{"is":"","en":""},"defaultLanguage":"en"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/node_modules/@kogk/gatsby-plugin-ui-lib/dist/src/components/DefaultLayout/DefaultLayout.js"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/icelandic-pledge"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
