// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-kogk-gatsby-plugin-ui-lib-dist-src-components-directory-directory-js": () => import("./../../../../node_modules/@kogk/gatsby-plugin-ui-lib/dist/src/components/Directory/Directory.js" /* webpackChunkName: "component---node-modules-kogk-gatsby-plugin-ui-lib-dist-src-components-directory-directory-js" */),
  "component---src-components-site-burger-burger-mdx": () => import("./../../../src/components/site/Burger/Burger.mdx" /* webpackChunkName: "component---src-components-site-burger-burger-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-tsx": () => import("./../../../src/pages/de.tsx" /* webpackChunkName: "component---src-pages-de-tsx" */),
  "component---src-pages-es-tsx": () => import("./../../../src/pages/es.tsx" /* webpackChunkName: "component---src-pages-es-tsx" */),
  "component---src-pages-fr-tsx": () => import("./../../../src/pages/fr.tsx" /* webpackChunkName: "component---src-pages-fr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

